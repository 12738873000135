<template>
    <form @submit.prevent="">

        <div class="mb-3" v-for="(field, index) in fields" :key="index">

            <label for="entry-title" class="form-label">{{ field?.title }}</label>

            <!-- text -->
            <input v-if="field?.type == 'text'" v-model="current.entry[field?.key]" @change="updated" class="form-control text-input fs-5" id="entry-title" type="text" :name="field?.key">

            <!-- number -->
            <input v-if="field?.type == 'number'" v-model="current.entry[field?.key]" @change="updated" class="form-control text-input fs-5" id="entry-title" type="number" :name="field?.key">

            <!-- color -->
            <div v-if="field?.type == 'color'" class="d-flex flex-row color-field">
                <input v-model="current.entry[field?.key]" @change="updated" class="form-control text-input" :id="'field-color-' + field?.key" type="text" :name="field?.key"
                    @blur="($event)=>updateColor('field-color-picker-' + field?.key, $event.target.value, field?.key)">
                    
                <input v-model="current['color_' + field?.key]" @change="updated" class="form-control color-input p-1 w-25 ms-3" :id="'field-color-picker-' + field?.key" type="color" 
                    style="height: auto"
                    placeholder="#eeeeee"
                    @blur="($event)=>updateColor('field-color-' + field?.key, $event.target.value, field?.key)">
            </div>

            <!-- textarea -->
            <textarea v-if="field?.type == 'textarea'" v-model="current.entry[field?.key]" @change="updated" class="form-control text-input fs-5" id="entry-title" :name="field?.key"></textarea>


            <select v-if="field?.type == 'select'"  v-model="current.entry[field?.key]" @change="updated" class="form-select" aria-label="Default select example">
                <option v-for="(option, o) in field.options" :key="o" :value="option.key">{{ option.value }}</option>
            </select>

            
            <input v-if="!['text', 'number', 'color', 'textarea', 'select'].includes(field?.type)" v-model="current.entry[field?.key]" @change="updated" class="form-control text-input fs-5" id="entry-title" type="text" :name="field?.key">

            <small v-if="field?.hint" class="fs-6 text-muted">{{ field?.hint }}</small>
        </div>  
    </form>
</template>

<script>
export default {
    name: 'FormStack',

    props: {
        fields: Object,
        updated: {
            type: Function,
            default: function() {
                return ()=>{}
            }
        }
    },

    data() {
        return {
            current: {
                entry: {
                },
            },
            originalEntry: null
        }
    }, 

    methods: {


        updateColor(id, value, key) {
            this.$el.querySelector('#' + id).value = value;
            this.current.entry[key] = value;
        },


        updateColors() {
            let $ = window.$;
            $('.color-field').each((i, item)=>{
                let value = $(item).find('.text-input').val();
                $(item).find('.color-input').val(value);
            });
        }, 
    }, 

    mounted() {
        setTimeout(()=>{
            this.updateColors();
        }, 250);
    }


}
</script>

<style>

</style>