<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 bg-canvas" data-cmp="OpportunityDetails">
        <HeaderMain></HeaderMain>

        <!-- subnavigation -->
        <div class="container-fluid border-bottom bg-white shadow-sm position-relative py-2 px-4 d-flex align-items-center">
            <span>
                <ButtonCircle class="btn-primary" icon="navigate_before" :onClick="$router.back"></ButtonCircle>

                <nav class="d-inline-block ms-3" style="--bs-breadcrumb-divider: '›';">
                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                        <li class="breadcrumb-item">
                          <a class="text-decoration-none" href="#"
                            @click.prevent="$root.goto( $root.links.APPS )"
                          >Apps</a>
                        </li>
                        <li class="breadcrumb-item">{{ current.app?.title }}</li>
                        <li v-if="current?.form?.title" class="breadcrumb-item">{{ current?.workbook?.title }}</li>
                    </ol>
                </nav>
            </span>

            <span class="flex-fill"></span>

            <span>
                <!-- <ButtonCircle class="" icon="file_upload"></ButtonCircle>
                <ButtonCircle class="ms-2" icon="library_add"></ButtonCircle> -->
                <ButtonMain class="ms-2 rounded-pill btn-sm fs-6" icon="east" iconPosition="right" :onClick="launchApp"
					:class="[!$route.params?.app? 'disabled' : '']"
				>Launch</ButtonMain>
            </span>
        </div>


		<!-- editable content -->
        <div class="bg-white flex-fill">
			<div class="d-flex flex-row h-100">
				<!-- WORKBOOKS -->
				<div class="workbook-editor-sidebar min-height border-end">
					<div class="d-flex flex-row border-bottom align-items-center py-2 px-4">
						<h4 class="mb-0 pb-0 fs-5">Workbooks</h4>

						<div class="d-inline-block flex-fill text-end py-1">
							<!-- <button type="submit" class="btn btn-sm py-0 btn-outline-dark text-uppercase me-2" @click="importFormIntent">IMPORT</button> -->
							<button type="submit" class="btn py-0 btn-outline-primary text-uppercase" @click="selectWorkbookIntent">ADD</button>

							<div class="dropdown float-end ms-2 me-1">
								<button class="btn btn-sm dropdown-toggle no-carret" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									<span class="material-icons-outlined position-absolute"
										style="transform: translateY(-11px);"
										>
									more_horiz
									</span>
								</button>
								<ul class="dropdown-menu border-radius-8 shadow border-minimal py-3" aria-labelledby="dropdownMenuButton1">
									<li><a class="dropdown-item px-3 py-1 lh-base transition-speed-5x hover:bg-light text-truncate disabled" href="#">New Workbook</a></li>
									<li><a class="dropdown-item px-3 py-1 lh-base transition-speed-5x hover:bg-light text-truncate disabled" href="#"
										@click.prevent="sort('workbooks')"
										>Sort</a></li>
									<li><hr class="dropdown-divider"></li>
									<li><a class="dropdown-item px-3 py-1 lh-base transition-speed-5x hover:bg-light text-truncate disabled" href="#">Delete All</a></li>
								</ul>
							</div>
						</div>
					</div>


					<div class="px-2 pt-4">
						<ListGroup class="align-to-left" :collection="collection" :active="current.workbook" :onClick="setCurrentWorkbook" :onOption="workbookOnOption" :options="workbookOptions"></ListGroup>
					</div>
				</div>



				<!-- FORMS -->
				<div class="workbook-editor-content min-height flex-fill">

					<div class="d-flex flex-row border-bottom align-items-center py-2 px-4">
						<h4 class="mb-0 pb-0 fs-5">Forms</h4>

						<div class="d-inline-block flex-fill text-end py-1" style="height: 34px">
							<!-- buttons here -->
						</div>
					</div>

					<div class="px-2 pt-2">
						<ul class="form-fileds list-group list-group-flush px-2">
							<li v-for="(form, index) in forms" :key="index" 
								:id="`list-group-item-${form.name}`"
								:class="[form?.name == current?.form?.name? 'active' : '']"
								class="list-group-item d-flex align-items-center">
								<span class="material-icons-outlined float-start text-muted">
									{{workbookIcon( form?.type || 'text')}}
								</span>
								<a href="#" @click.prevent="setCurrentForm(form)" class="ms-4 text-decoration-none text-reset fw-500 flex-fill">{{ form.title }}</a>

								<span class="list-group-item-options">
									<!-- <ButtonCircle class="ms-2" icon="library_add"></ButtonCircle> -->
									<OptionsDropdown class="ms-2" :context="form" :collection="formOptions" :onClick="formOnOption"></OptionsDropdown>
								</span>
							</li>
						</ul>
					</div>
				</div>


				<!-- SETTINGS -->
				<div class="workbook-editor-details min-height border-start">
					<div class="d-flex flex-row border-bottom align-items-center py-2 px-4">
						<h4 class="mb-0 pb-0 fs-5">Settings</h4>

						<div class="d-inline-block flex-fill text-end py-1">
							<button type="submit" class="btn py-0  text-uppercase" 
								:class="[!settingsPanelUpdated? 'disabled btn-outline-dark border-light bg-light': 'btn-outline-primary']"
								@click="updateFormSettings">UPDATE</button>
						</div>
					</div>

                    <FormStack ref="formSettings" :fields="settings.fields" :updated="settingsHasBeenUpdated" class="p-4"></FormStack>
				</div>
			</div>
        </div>

		<FooterMain class="bg-white"></FooterMain>
    </div>
</template>

<script>

import FormStack from "./widgets/FormStack.vue";

export default {

	components: {
		FormStack
	},

	data() {
		return {
			current: {
				workbooks: null,
				workbook: null, 
				forms: null,
				form: null,
				field: null
			},

            settings: {
                fields: [
                    {
                        title: 'UI: Mode',
                        key: 'UIMode',
                        type: 'select', 
						options: [
							{ key: 'right-panel', value: 'Right Panel'},
							{ key: 'left-panel', value: 'Left Panel'},
							{ key: 'modal', value: 'Modal'},
							{ key: 'popup', value: 'Popup'},
						]
                    },
                    {
                        title: 'UI: List',
                        key: 'UIList',
                        type: 'text'
                    },
                    {
                        title: 'UI: Editor',
                        key: 'UIEditor',
                        type: 'text'
                    },
                    {
                        title: 'Hook: Create',
                        key: 'hookCreate',
                        type: 'text'
                    },
                    {
                        title: 'Hook: Read',
                        key: 'hookRead',
                        type: 'text'
                    },
                    {
                        title: 'Hook: Update',
                        key: 'hookUpdate',
                        type: 'text'
                    },
                    {
                        title: 'Hook: Delete',
                        key: 'hookDelete',
                        type: 'text'
                    },
                ], 

                defaults: {
                    UIList: 'default',
                    UIEditor: 'default',
                    hookCreate: '',
                    hookUpdate: '',
                    hookDelete: '',
                }
            }, 

			settingsPanelUpdated: false,
		}
	},



	computed: {
		collection() {
			(this.workbooks?.length > 0) && this.workbooks.map( (item) => {
				item.icon = 'library_books';
			})
			return this.workbooks;
		}, 

		workbookOptions() {
			let clean = { ...this.$ui.helper.options.GENERIC }; clean.title = 'Clean Data'; clean.disabled = true;
			let embed = { ...this.$ui.helper.options.GENERIC }; embed.title = 'Embed Code'; embed.disabled = true;
			let publicURL = { ...this.$ui.helper.options.GENERIC }; publicURL.title = 'Generate URL'; publicURL.disabled = true;


			return [
                this.$ui.helper.options.EDIT,
                this.$ui.helper.options.MOVE_TO,
                this.$ui.helper.options.DIVIDER,
				publicURL,
                clean,
				embed,
                this.$ui.helper.options.DIVIDER,
                this.$ui.helper.options.DELETE,
            ]
		}, 

		formOptions() {
			let publicURL = { ...this.$ui.helper.options.GENERIC }; 
				publicURL.title = 'Public URL';
				publicURL.name = 'publicURL';
			let shareForm = { ...this.$ui.helper.options.GENERIC }; shareForm.title = 'Share Form'; shareForm.disabled = true;
			let fieldOptions = { ...this.$ui.helper.options.GENERIC }; fieldOptions.title = 'Field Options'; fieldOptions.disabled = true;

			return [
                publicURL,
                shareForm,
				fieldOptions,
            ]
		},

		workbooks() {
			let order = this.current?.app?.content?.workbooks;
			let workbooks = this.current?.workbooks;

			if(!order || !workbooks || !this.current?.app) {
				return [];
			}
			
			let orderNum = order.map( (i) => { return parseInt(i) });
			let collection = this.sortOn('ID', orderNum, workbooks);
			return collection || [];
		},


		forms() {
			// return this.$store.state?.forms || [];
			return this.current?.forms || [];
		},

		fields() {
			return this.$store.state?.current?.form?.content?.fields || this.current.form?.content?.fields || [];
		},

	},

	watch: {
		'$route' (to) {
			if(this.$route.params?.app && to?.name == "AppDetails") {
				this.setCurrentWorkbook();
			}
		}
	},


	methods: {

		launchApp() {
            let appName = this.current.app.name;
			let formName = this.$route.params?.form? '/' + this.$route.params?.form : '';
			let path = this.$root.links.APPS + '/run/' + appName + formName;
			this.$root.$router.push( {path: path} );
		},

		/**
		 * Form methods
		 */
		// async loadForms() {
		// 	this.forms = await this.$store.dispatch('getForms', this.current.workbook.ID);
		// 	return this.forms;
		// },


		/**
		 * +----------------------------------+
		 * | WORKBOOK                         |
		 * +----------------------------------+
		 */
		

        selectWorkbookIntent() {
            this.selectWorkbook(async (data)=>{
                if(!this.$store.state.current?.app?.content?.workbooks?.length) {
                    !this.$store.state.current.app?.content && (this.$store.state.current.app.content = {});
                    !this.$store.state.current.app?.content?.workbooks && (this.$store.state.current.app.content.workbooks = []);
                }

                this.$store.state.current.app.content.workbooks.push(data.ID)

                await this.$store.dispatch('updateApp', {
                    ID: this.$store.state.current.app.ID,
                    post_content: this.$store.state.current.app.content
                });

				await this.$store.dispatch('getWorkbooksInApp', this.current.app.ID );
            });
        },



        /**
         * Select a workbook
         */
        selectWorkbook(callback) {
            let modal = this.$root.modal({
                title: 'Select Workbook',
                okay: {
                    label: 'SELECT',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        typeof callback == 'function' && callback.apply(null, [component.current.selection]);
                    }
                }, 

            }, 'ModalContentSelector');

            setTimeout(async () => {
                let items = await this.$store.dispatch('getWorkbooks');
				let content = modal.content();
                    content.current.selection = null;
                    content.data.items = items;
            }, 0);

			return modal;
        },



		editWorkbookIntent(data) {
			console.log(data)

            this.$root.notify({
                title: 'Confirm',
                type: 'confirm',
                message: 'To edit a Wordbook or its Forms you must open it inside the Workbook Editor. Do you want to continue?',
                callback: (okay)=> {
                    if(okay) {
						let path = this.$root.links.WORKBOOKS +'/'+ data?.name;
						this.$router.push( {path: path} );
                        // for(let i=0; i< this.$store.state.current.app.content?.workbooks.length; i++) {

                        //     if(this.$store.state.current.app.content?.workbooks.length == 1) {
                        //         this.$store.state.current.app.content.workbooks = null;
                        //         break;
                        //     }

                        //     let id = this.$store.state.current.app.content?.workbooks[i];

                        //     if(id == data?.ID) {
                        //         this.$store.state.current.app.content?.workbooks.splice(i, 1);
                        //         break;
                        //     }
                        // }

                        // await this.$store.dispatch('updateApp', {
                        //     ID: this.$store.state.current.app.ID,
                        //     post_content: this.$store.state.current.app.content,
                        // });

                        // this.$root.toast(`The workbook "${data.title}" has been removed.`);

                        // await this.$store.dispatch('getWorkbooksInApp', this.current.app.ID );
                    }
                }
            });
		},




        async removeWorkbook(data) {
            console.log('removeWorkbook', data);

            if(!this.$store.state.current.app.content?.workbooks?.length) {
                return;
            }
                    
            this.$root.notify({
                title: 'Confirm',
                type: 'confirm',
                message: 'Would you like to remove the workbook "' + data.title + '" from this app?',
                callback: async (okay)=> {
                    if(okay) {
                        for(let i=0; i< this.$store.state.current.app.content?.workbooks.length; i++) {

                            if(this.$store.state.current.app.content?.workbooks.length == 1) {
                                this.$store.state.current.app.content.workbooks = null;
                                break;
                            }

                            let id = this.$store.state.current.app.content?.workbooks[i];

                            if(id == data?.ID) {
                                this.$store.state.current.app.content?.workbooks.splice(i, 1);
                                break;
                            }
                        }

                        await this.$store.dispatch('updateApp', {
                            ID: this.$store.state.current.app.ID,
                            post_content: this.$store.state.current.app.content,
                        });

                        this.$root.toast(`The workbook "${data.title}" has been removed.`);

                        await this.$store.dispatch('getWorkbooksInApp', this.current.app.ID );
                    }
                }
            });
        },



		async setCurrentWorkbook(data=null) {
			let routerMethod = 'push';
            routerMethod
			if(data == null && this.$route.params?.workbook && this.workbooks?.length > 0) {
				this.workbooks.forEach( (item) => {
					if(item?.name == this.$route.params?.workbook) {
						data = item;
					}
				});
			} else if(!this.$route.params?.workbook && this.workbooks?.length > 0) {
				routerMethod = 'replace';
				data = this.workbooks[0];
            }

			console.log('setCurrentWorkbook', data);

			this.$store.commit('SET_CURRENT_WORKBOOK', data );

			let path = this.$root.links.APPS + '/edit/'+ this.current.app.name +'/' + data.name /** workbook.name */;

            console.log(path);

			this.$router[routerMethod]( { path: path } );
			this.current.workbook = this.$store.state.current.workbook;
            await this.getForms();

			if(this.current?.forms?.length > 0) {
				this.setCurrentForm(this.current.forms[0]);
			}

			// this.resetAfter('workbook');
		},




        /**
         * Download, delete, or show details of a workbook based on
         * a selection from a dropdown menu.
         * 
         * @param option
         * @param option.name
         * @param option.title
         * @param option.type
         * @param option.icon
         * @param option.disabled
         * @param option.classes
         * 
         * @param data
         * @param data.ID
         * @param data.name
         * @param data.title
         * @param data.excerpt
         * @param data.content
         * @param data.meta
         * 
         */
		workbookOnOption(option, data) {
			console.log('workbookOnOption', option, data)
			let order, action = option?.name;

			switch (action) {
				case 'edit':
					this.editWorkbookIntent(data);
					break;

				case 'duplicate':
					
					break;
				case 'delete':
					this.removeWorkbook(data);
					break;
					
				case 'moveTo':
					order = this.current.app.content.workbooks.map( (i) => {return parseInt(i)} )
					this.moveWorkbookToPosition(data, order);
					break;
			
				default:
					break;
			}
		},



		sort(targets) {
			switch (targets) {
				case 'workbooks':
					
					break;
			
				default:
					break;
			}
		},

		sortOn(prop, order, items) {
			// console.group('sortOn');
			// console.log(order);
			// console.log(items);
			let sorted = [];	
			
			order.forEach( (position)=> {
				// console.log(position);

				items.forEach( (item) => {
					if(position == item[prop]) {
						console.log(item);

						sorted.push(item);
					}
				});
			});


			// console.groupEnd('sortOn');

			return sorted || items;
		},


		moveWorkbookToPosition(data, order) {
			let currentPosition;

			for(let i=0; i<order.length; i++) {
				if(order[i] == data.ID) {
					currentPosition = (i + 1);
					break;
				}
			}


			this.$root.notify({
				type: 'prompt',
				title: 'Set position',
				message: 'To which position would you like to move this item?',
				value: currentPosition,
				callback: async (okay, value) => {
					if(okay) {
						let targetPosition = parseInt(value) - 1;
							targetPosition = isNaN(targetPosition)? 0 : targetPosition;

						// console.log(okay);
						// console.log(value);
						// console.log('item ID', data.ID )
						// console.log('targetPosition', targetPosition )
						// console.log('order - original', order);

						order = order.filter( (item) => {
							if(item == data.ID) {
								return false;
							} else {
								return true;
							}
						});

						// console.log('order - filtered', order);

						if(targetPosition <= order.length) {
							order.splice(targetPosition, 0, data.ID)
						} else {
							targetPosition = order.length;
							order.splice(targetPosition, 0, data.ID);
							value = targetPosition + 1;
						}

						// console.log('order - splice', order);

						this.$store.state.current.app.content.workbooks = order;

						await this.$store.dispatch('updateApp', {
							ID: this.$store.state.current.app.ID,
							post_content: this.$store.state.current.app.content
						});

						await this.$store.dispatch('getWorkbooksInApp', this.current.app.ID );

						this.$root.toast(`The workbook "${data.title}" has been moved to the position ${value}.`);
					}
				}
			});
		},


		/**
		 * +----------------------------------+
		 * | FORMS                            |
		 * +----------------------------------+
		 */


        /**
         * @params {Object} form
         * @params {Object} form.ID
         * @params {Object} form.title
         * @params {Object} form.name
         * @params {Object} form.excerpt
         */
        // editFormIntent(form) {

		// 	console.log('editFormIntent', form);

        //     let modal = this.$root.modal({
        //         title: 'Update Form',
        //         context: form,
        //         okay: {
        //             label: 'UPDATE',
        //             visible: true,
        //             disabled: false,
        //             callback: async (component) => {
        //                 let request = {
        //                     ID: form?.ID,
        //                     post_title: component.data.title,
        //                     post_excerpt: component.data.excerpt,
        //                     meta_input: {
        //                         workbook: component.data.workbook,
        //                         group: component.data.group,
        //                     }
        //                 };
        //                 this.editForm(request);
        //             }
        //         }, 

        //     }, 'ModalContentForm');

        //     setTimeout(() => {
		// 		let content = modal.content();
        //         content.data.title = form.title;
        //         content.data.excerpt = form.excerpt;
        //         content.data.workbook = form?.meta?.workbook;
        //         content.data.group = form?.meta?.group || '';
		// 		content.validate = function() {
		// 			let isValid = false;
		// 			if('title' in this.data && this.data.title?.length > 0) {
		// 				isValid = true;
		// 			}
		// 			if(!isValid) {
		// 				alert('Please fill out all required fields');
		// 			}
		// 			return isValid;
		// 		};
        //     }, 0);
        // },





        async getForms(request) {
            request

            if(!this.current?.workbook?.ID) {
                return;
            }

            this.current.forms = await this.$root.$store.dispatch('getFormsByWorkbookId', { workbookId: this.current?.workbook?.ID, forceRefresh: true });

			// console.log(request);
            // await this.$root.$store.dispatch('updateForm', request);
            // await this.refresh(true);
            // this.$root.toast(`The workbook "${request.post_title}" has been updated.`);
        },


		formOnOption(option, form) {
			console.log('formOnOption', option, form);

			switch (option?.name) {
				case 'publicURL':
					this.showFormPublicURL(form);
					break;

				case 'details':
					
					break;

				case 'duplicate':
					
					break;

				case 'delete':
					
					break;
			
				default:
					break;
			}
		},


		setCurrentForm(data) {
			this.current.form = data;
            this.showFormSettings(this.current.form);
		},



		showFormPublicURL(data) {
			let path = this.$root.links.PUBLIC_FORMS + '/' + data.name;
			this.$root.notify({
				type: 'confirm',
				message: `Would you like to open the form ${data.title} public URL? <br/><br/>${window.location.origin}${path}`,
				callback: (okay)=>{
					if(okay) {
						this.$router.push( {path: path} )
					}
				}
			});
		},


		/**
		 * +----------------------------------+
		 * | SETTINGS PANEL                   |
		 * +----------------------------------+
		 */

        showFormSettings(data) {
            let defaults = {...this.settings.defaults};
            if(!this.$store.state.current?.app?.content?.forms) {
                !this.$store.state.current.app?.content && (this.$store.state.current.app.content = {});
                !this.$store.state.current.app?.content?.forms && (this.$store.state.current.app.content.forms = {});
                !this.$store.state.current.app?.content?.forms?.[data?.name] && (this.$store.state.current.app.content.forms[data?.name] = defaults);
            }
			this.$refs.formSettings.current.entry = {...this.$store.state.current.app?.content?.forms?.[data?.name]};
        },


        async updateFormSettings() {
            let settings = this.$refs.formSettings.current.entry;

            this.$store.state.current.app.content.forms[this.current.form?.name] = settings;

            await this.$store.dispatch('updateApp', {
                ID: this.$store.state.current.app.ID,
                post_content: this.$store.state.current.app.content
            });

			this.settingsPanelUpdated = false;
        },

		settingsHasBeenUpdated() {
			this.settingsPanelUpdated = true;
		},







        /**
         * @params {Object} request
         * @params {Object} request.ID
         * @params {Object} request.post_title
         * @params {Object} request.post_excerpt
         */
        // async editForm(request) {

		// 	console.log(request);
        //     await this.$root.$store.dispatch('updateForm', request);
        //     await this.refresh(true);
        //     this.$root.toast(`The workbook "${request.post_title}" has been updated.`);
        // },


		/**
		 * 
		 * @param {Object} data The for data
		 * @param {Object} data.ID 
		 * @param {Object} data.title
		 * @param {Object} data.name
		 * @param {Object} data.excerpt
		 * 
		 */
		// deleteForm(data) {
		// 	this.$root.notify({
		// 		title: 'Confirm',
		// 		type: 'confirm',
		// 		message: 'Are you sure you want to delete this form (' + data.title + ')?',
		// 		callback: async (okay)=> {
		// 			if(okay) {
		// 				await this.$store.dispatch('deleteForm', data);
		// 				this.$root.toast(`The form "${data.title}" has been deleted.`);
		// 				this.forms = await this.$store.dispatch('getFormsByWorkbookId', { workbookId: this.current.workbook.ID, forceRefresh: true } );
		// 			}
		// 		}
		// 	});
		// },


		workbookIcon(type) {
			let icon = 'list_alt';
			switch (type) {
				case 'textarea':
					icon = 'notes';
					break;

				case 'color':
					icon = 'palette';
					break;

				case 'number':
					icon = 'tag';
					break;

				case 'url':
					icon = 'link';
					break;

				case 'email':
					icon = 'email';
					break;

				case 'tel':
					icon = 'call';
					break;
			
				case 'text':
				default:
					break;
			}
			return icon;
		},


		// async updateFieldData(data) {
		// 	let request = {
		// 		form_id: this.current.form?.ID,
		// 		field: data
		// 	};

		// 	console.log('updateFieldData', request);

		// 	await this.$root.$store.dispatch('updateFormField', request);
		// 	this.forms = await this.$store.dispatch('getFormsByWorkbookId', { workbookId: this.current.workbook.ID, forceRefresh: true } );

		// 	console.log('%cthis.forms', 'color: red', this.forms);

		// 	this.$root.toast(`The field "${data.title}" has been updated.`);
		// },





		resetAfter(panel) {
			switch (panel) {
				case 'form':
					this.$refs.fieldDetails.attachSource({});
					this.current.field = null;
					break;
			
				case 'field':
				default:
					break;
			}
		},


		/**
		 * Others
		 */
		async refresh(forceRefresh = false) {
            forceRefresh
			let name = this.$route.params?.app || null;
			if(name) {
				this.current.app = await this.$store.dispatch('getApp', name);
				this.current.workbooks = await this.$store.dispatch('getWorkbooksInApp', this.current.app.ID );
				this.setCurrentWorkbook();

			}
		}
	},

	async mounted() {
		// this.current.form = null;
		// this.current.field = null;
		await this.refresh(true);
		window.appDetails = this;
	}
};
</script>

<style scope>
.min-height {
	min-height: 50vh;
}


.workbook-editor-sidebar {
	width: 320px;
	z-index: 10;
}

.workbook-editor-content {

}

.workbook-editor-details {
	width: 500px;
}

.list-group-item.active {
	background-color: var(--primary-light);
	border-color: transparent;
	color: rgb(33, 37, 41);
}
.list-group-item .fw-500 {
	font-weight: 500;
}


.list-group-item .list-group-item-options {
	opacity: 0;
	transition: all 0.2s;
}
.list-group-item:hover .list-group-item-options {
	opacity: 1;
}


.align-to-left .dropdown-menu {
    margin-left: 164px !important;
}

</style>